<template lang="pug">
  div
    .container-fluid.wrapper
      .row.justify-content-md-center
        div.text-center(v-if="falseInfo")
          .my-5 情報の取得に失敗しました
          buttom.my-2(@click.prevent="$router.go(-1)" style="cursor: pointer; color:blue") 前のページに戻る
        .col-12.text-center
          b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isUserInfoLoading")
      .div(v-if="!isUserInfoLoading && !falseInfo")
        //-ユーザー名
        .row.justify-content-md-center.user-name.pt-2
          .col-12.text-left
            <b>{{userName}}</b>
        //-友達リクエスト、プロフィール修正のリンク
        .row.justify-content-md-center.top-link-area
          //-ログインユーザー自身の場合、プロフィール修正
          .col-12.text-right
            router-link(to="/user/edit/input" disabled="" v-if="isTargetIsLoginUserId") <small>プロフィール修正</small>
          //-友達リクエスト
          FriendRequest.friend-riquest(v-if="!isTargetIsLoginUserId")
        //-バッジ、ステータス
        .row.status-area.align-items-center
          .col-4.col-md-2.text-center
            .left-box
              .status-badge.justify-content-md-center
                .badge
                  img(:src="createImgPath(iconPath)")
          .col-8.col-md-5
            .right-box
              .status-name Level {{userLevel}} : {{getLevelName(userLevel)}}
              .status-bar
                .status-bar-innner
                  .element.text-right ポイント:
                  b-progress(variant="info" :value="climbingPoint" :max="statusBarMax" show-value="")
                .status-bar-innner
                  .element.text-right 岩場達人度:
                  b-progress(variant="info" :value="rockyMasterPoint" :max="statusBarMax" show-value="")
                .status-bar-innner
                  .element.text-right 技術Lv:
                  b-progress(variant="info" :value="techniquePoint" :max="techniquePointBarMax" show-value="")
        //-自己紹介
        .row.justify-content-md-center.bottom-text-area
          .col-12
            .bottom-text(v-if="isTargetIsLoginUserId")
              .title <b>次のレベルまで</b>
              .text あと{{pointRequiredNextLevel/10}}チェックイン、または、{{pointRequiredNextLevel}}登攀ポイント獲得
            .bottom-text(v-if="!isTargetIsLoginUserId & profileText != ''")
              .title <b>自己紹介</b>
              .text {{profileText}}

        //-タブエリア
        .row.justify-content-md-center.tab-area
          .col-12
            .navi-container
              .tab(:class="{'active':isTopTab}" @click="changeTabAndRouterPush('userInfoTop')") TOP
              .tab(:class="{'active':isBadgeTab}" @click="changeTabAndRouterPush('badgeList')") バッジ
              .tab(:class="{'active':isCheckInRallyTab}" @click="changeTabAndRouterPush('checkInRally')") チェックインラリー
              .tab(:class="{'active':isRockyTab}" @click="changeTabAndRouterPush('rockyList')") 岩場
              .tab(:class="{'active':isAllRouteTab}" @click="changeTabAndRouterPush('allRouteList')") 全ルート
              .tab(:class="{'active':isCheckInHistoryTab}" @click="changeTabAndRouterPush('checkInHistoryList')") チェックイン
              .tab(:class="{'active':isFriendsTab}" @click="changeTabAndRouterPush('friendsList')") 友達
            //-childコンポーネントのrouter-view
            .child-area
              router-view(ref="myPageChild")
</template>

<script>
import axios from 'axios'
import FriendRequest from '@/components/friend/friendRequest.vue'

export default {
  // データオブジェクト
  data() {
    return {
      //基本情報
      isLogin : this.$user.isLogin,
      userId : this.$route.params.userId,
      idToken : this.$user.idToken,
      userName:'',
      iconPath:'',
      profileText :'',
      //表示対象のユーザーIDがログインユーザー自身のものであるか
      isTargetIsLoginUserId:false,
      //ステータスバー
      userLevel: 0,
      rockyMasterPoint: 0,
      climbingPoint: 0,
      techniquePoint: 0,
      pointRequiredNextLevel: 0,
      statusBarMax: 1000,
      techniquePointBarMax: 7,
      //masterJsonから取得した値
      badgeIconPathList:[],
      checkInRallyList:[],
      //子コンポーネントで使用
      checkinRockyIdList: {},
      prefecturesList: {},
      allRouteDedupliList: {},
      completeDedupliClimbList: {},
      checkinRockIdList: {},
      routeLevelList: {},
      climbingAndCheckinCount:{},
      ranking:{},
      checkinRockyTotalCnt:0,
      checkinRockTotalCnt:0,
      //友達リクエストの表示制御
      isFriendRequestLinkShow:true,
      //マイページ自体の参照権限
      authNotExists:true,
      //記録、チェックイン情報の参照権限
      recordAuth:false,
      checkinAuth:false,
      //タブの表示制御
      isTopTab:false,
      isBadgeTab:false,
      isCheckInRallyTab:false,
      isRockyTab:false,
      isAllRouteTab:false,
      isCheckInHistoryTab:false,
      isFriendsTab:false,
      //spinner表示用
      isUserInfoLoading:true,
      falseInfo: false
    }
  },
  // インスタンス作成後
  created() {
    this.isLogin = this.$user.isLogin
    this.createdProcess()
  },
  //同一パス(パラメータのみ変更）の移動の場合コンポーネント再利用され
  //再createされない仕様の為、
  //watchでクエリストリングの変更をフックして制御する。
  watch: {
    '$route' (to, from) {
      //パスが変わる度にログイン状態を取得する
      this.isSignin = this.$user.isLogin
      this.idToken = this.$user.idToken

      //タブ変更された場合、変更後のタブを有効にする
      //対象のパスを選択状態にする
      this.changeTabForRouterWatch(to)

      //表示対象のユーザーIDが変更となった場合、再描画する
      if(from.params.userId != to.params.userId){
        this.$router.go({path: to, force: true})
      }
    }
  },
  // メソッド
  methods: {
    async createdProcess(){
      //ログイン中の場合は、表示対象のユーザーIDがログインユーザー自身のものであるかチェックする。
      if(this.$user.isLogin && this.userId == this.$user.userId){
        this.isTargetIsLoginUserId = true
      }

      //対象のパスを選択状態にする
      this.changeTabForRouterWatch(this.$route)

      //master.json取得
      this.getMasterJson()

      //登攀件数取得
      this.getUserClimbingCheckinCount()
      //ランキング取得
      this.getUserRanking()

      //ユーザー情報取得
      await this.getUserInfo()
      //レベルや登攀記録Listを取得する
      await this.getLevelinfoAndRecords()

      this.isUserInfoLoading = false

      //ユーザーが存在しないか、非公開ユーザーの場合トップページに遷移する
      if(!this.authNotExists){
        await this.asyncDialog('指定されたユーザーは表示できません。')
        this.$router.push('/') 
      }
    },
    //-------------
    // タブ切り替え
    //-------------
    changeTabAndRouterPush(target){
      this.changeTab(target)
      this.doRouterPush(target)
    },
    changeTabForRouterWatch(param){
      //パスからタブ名を抽出する
      var str = param.path
      var cut_str = param.params.userId
      var index = str.indexOf(cut_str)
      str = str.slice(index + 1);
      index = str.indexOf('/')
      str = str.slice(index + 1);
      //対象のタブをactiveにする
      this.changeTab(str)
    },
    changeTab(target){
      //一旦全タブをfalseにする
      this.isTopTab=false
      this.isRockyTab=false
      this.isBadgeTab=false
      this.isCheckInRallyTab=false
      this.isRockyTab=false
      this.isAllRouteTab=false
      this.isCheckInHistoryTab=false
      this.isFriendsTab=false
      //タブの切り替えとURL(クエリストリング)の上書き
      switch (target){
        case 'userInfoTop':
          this.isTopTab = true
          break
        case 'badgeList':
          this.isBadgeTab = true
          break
        case 'checkInRally':  
          this.isCheckInRallyTab = true
          break
        case 'rockyList':  
          this.isRockyTab = true
          break
        case 'rockList':  
          this.isRockyTab = true
          break
        case 'routeList':  
          this.isRockyTab = true
          break
        case 'routeDetail':  
          this.isRockyTab = true
          break
        case 'allRouteList':  
          this.isAllRouteTab = true
          break
        case 'checkInHistoryList':  
          this.isCheckInHistoryTab = true
          break
        case 'friendsList':  
          this.isFriendsTab = true
          break
        default:
          //タブの指定がない場合ここに到達するので、TOPページに遷移する
          this.isTopTab = true
          this.$router.replace('/myPage/' + this.$route.params.userId + '/userInfoTop')
          break
      }
    },
    doRouterPush(target){
      this.$router.push('/myPage/' + this.$route.params.userId + '/' + target)
    },
    getLevelName(level){
      let wkLevel = this.badgeIconPathList.filter(item => item.level == level)
      return wkLevel[0].name      
    },
    //------------------------
    //ユーザー情報取得
    //------------------------
    async getUserInfo() {

      let jsonUrl = ''
      if(this.isLogin){
        //ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/my-page-top-logined'
      }else{
        //未ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/my-page-top-not-logined'
      }

      await axios
        .get(jsonUrl,{
          params: {
            'userId':this.userId
          },
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {

          //非公開ユーザ、または存在しないユーザの場合
          //messageに「USER_DO_NOT_EXISTENT」「PRIVATE」がセットされる
          //画面にも表示しない。
          if(response.data.message != ''){
            this.authNotExists = false
            return
          }

          let resp = response.data

          this.userName = resp.name
          this.iconPath = resp.iconPath
          this.profileText = resp.profileText
          //友達一覧
          this.friendList = resp.friendList
          //記録、チェックイン情報の参照権限
          this.recordAuth = resp.recordAuth
          this.checkinAuth = resp.checkinAuth
        })
        .catch(err => {
          this.falseInfo = true
          this.isUserInfoLoading = false
        })
    },
    //------------------------
    //登攀件数取得
    //------------------------ 
    async getUserClimbingCheckinCount(){
      let jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/user-climbing-checkin-count'

      await axios
        .get(jsonUrl,{
          params: {
            'userId': this.userId
          },         
          headers: {
            'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
          }
        })
        .then(response => {
          let result = response.data
          //登攀件数・チェックイン件数
          this.climbingAndCheckinCount = {
            //登攀件数
            'climbAllRouteCount':result.climbingCount.climbAllRoute,
            'climbCompleteRouteCount':result.climbingCount.climbCompleteRoute,
            //チェックイン
            'prefecturesCount':result.checkinCount.prefectures,
            'checkinRockyCnt':result.checkinCount.rocky,
            'checkinRockCnt':result.checkinCount.rock
          }

        })
        .catch(err => {
          this.falseInfo = true
          this.isUserInfoLoading = false
        })
    },
    //------------------------
    //ランキング取得
    //------------------------ 
    async getUserRanking(){
      let jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/user-ranking'

      await axios
        .get(jsonUrl,{
          params: {
            'userId': this.userId
          },         
          headers: {
            'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
          }
        })
        .then(response => {
          let result = response.data

          //ランキング
          this.ranking = {
            'climbAllRouteRank':result.ranking.climbAllRouteRank,
            'climbCompleteRouteRank':result.ranking.climbCompleteRouteRank,
            'checkinPrefecturesRank':result.ranking.checkinPrefecturesRank,
            'checkinRockyRank':result.ranking.checkinRockyRank,
            'checkinRockRank':result.ranking.checkinRockRank
          }
        })
        .catch(err =>{
          this.falseInfo = true
          this.isUserInfoLoading = false
        })

    },
    //-------------------------
    //カウントアップテーブル取得
    //-------------------------  
    async getLevelinfoAndRecords() {

      let jsonUrl = ''
      if(this.isLogin){
        //ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/countup-logined'
      }else{
        //未ログイン時
        jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-user-info/countup-not-logined'
      }

      await axios
        .get(jsonUrl,{
          params: {
            'userId': this.userId,
          },         
          headers: {
            'Authorization': this.$user.idToken
          }
        })
        .then(response => {
          let result = response.data
          this.userLevel =  result.level
          this.pointRequiredNextLevel = result.pointRequiredNextLevel
          //ステータスバーの表示値
          this.rockyMasterPoint = result.rockyMasterPoint
          this.climbingPoint = result.climbingScore
          this.techniquePoint = result.climbingTechLevel
          //チェックイン済みのIDリスト(子コンポーネントから参照される)
          this.checkinRockyIdList = result.checkinRockyIdList
          this.checkinRockIdList = result.checkinRockIdList
          this.prefecturesList = result.prefecturesList
          //チェックイン累計件数
          this.checkinRockyTotalCnt = result.checkinRockyTotalCnt
          this.checkinRockTotalCnt = result.checkinRockTotalCnt
        })
        .catch(err => {
          this.falseInfo = true
          this.isUserInfoLoading = false
        })

    },
    //------------------------
    //master.json取得
    //------------------------   
    getMasterJson() {
      //master.jsonの取得
      axios.get(process.env.VUE_APP_MATERIAL_URL + '/master.json', {
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.badgeIconPathList = res.data.badge
        this.checkInRallyList = res.data.checkInRally
      })

      this.$bvModal.show('status-modal')
      return
    },
    createImgPath(path){
      return this.getStatusIconPath(path)
    },
    asyncDialog(message) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(message, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    //マイページ上でチェックイン実行された場合、最新のデータにリロードする処理
    //HeaderNaviComponent.vueから呼ばれる
    async reloadMyPage(){
      await this.createdProcess()

      //チェックインタブを表示している場合は、データ再取得を実行する。
      if(this.$route.path.match('/checkInHistoryList')){
        this.$refs.myPageChild.getCheckinHistory()
      }
    }
  },
  // コンポーネント
  components: {
    FriendRequest
  }
}
</script>

<style lang="stylus" scoped>
#app
  .container-fluid
    background #eceeef
    min-height 80vh
    //-友達リクエスト、プロフィール修正のリンク
    .top-link-area
      font-size 4vw
      .friend-riquest
        padding-right 1rem
    //-ユーザー名
    .user-name
      font-size 4.5vw
    //-バッジ、ステータス
    .status-area
      .left-box
        .status-badge
          .badge
            img
              width 100%
              height auto
              border-radius 50%
      .right-box
        margin-left -15px
        .status-name
          font-weight 600
        .status-bar
          .status-bar-innner
            display flex
            margin 0.2rem 0
            .element
              width 30%
              text-align center
              font-size 2.5vw
              font-weight 700 
              padding-right 0.5rem
            .progress
              width 70%
              height 10px
              color red
              background #C3C8D9

    //-自己紹介
    .bottom-text-area
      font-size 3vw
      margin-bottom 1rem
    //-タブエリア
    .tab-area
      .navi-container
        margin-left -15px
        margin-right -15px
        white-space nowrap
        overflow-y hidden
        overflow-x auto
        display flex
        .tab
          height 35px
          line-height 35px
          text-align center
          padding 0 0.7rem
          background #cfd3d7
          color #606e79
          cursor pointer
          border-left 0.5px solid #eceeef
          border-right 0.5px solid #eceeef
        .active
          background #004170
          color #FFF
          border-bottom 2px solid #2d8fd5
    
@media (min-width 992px)
  #app
    .container-fluid
      .top-link-area
        font-size 1rem
      .user-name
        font-size 1.5rem
      .bottom-text-area
        font-size 1rem
      //-バッジ、ステータス
      .status-area
        .left-box
          .status-badge
            .badge
              img
                max-width 150px
                width 100%
        .right-box
          .status-name
            font-size 1.5rem
            font-weight 600
          .status-bar
            .status-bar-innner
              display flex
              .element
                width 30%
                text-align center
                font-size 1.2rem
                font-weight 700
              .progress
                width 70%
                height 1rem
                margin-top 0.4rem
</style>
