var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container-fluid wrapper" }, [
      _c("div", { staticClass: "row justify-content-md-center" }, [
        _vm.falseInfo
          ? _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("div", { staticClass: "my-5" }, [
                  _vm._v("情報の取得に失敗しました")
                ]),
                _c(
                  "buttom",
                  {
                    staticClass: "my-2",
                    staticStyle: { cursor: "pointer", color: "blue" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [_vm._v("前のページに戻る")]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-12 text-center" },
          [
            _vm.isUserInfoLoading
              ? _c("b-spinner", {
                  staticClass: "mt-5 mb-5",
                  attrs: { variant: "primary", label: "Loading..." }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      !_vm.isUserInfoLoading && !_vm.falseInfo
        ? _c("div", { staticClass: "div" }, [
            _c(
              "div",
              { staticClass: "row justify-content-md-center user-name pt-2" },
              [
                _c("div", { staticClass: "col-12 text-left" }, [
                  _c("b", [_vm._v(_vm._s(_vm.userName))])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "row justify-content-md-center top-link-area" },
              [
                _c(
                  "div",
                  { staticClass: "col-12 text-right" },
                  [
                    _vm.isTargetIsLoginUserId
                      ? _c(
                          "router-link",
                          { attrs: { to: "/user/edit/input", disabled: "" } },
                          [_c("small", [_vm._v("プロフィール修正")])]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.isTargetIsLoginUserId
                  ? _c("FriendRequest", { staticClass: "friend-riquest" })
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "row status-area align-items-center" }, [
              _c("div", { staticClass: "col-4 col-md-2 text-center" }, [
                _c("div", { staticClass: "left-box" }, [
                  _c(
                    "div",
                    { staticClass: "status-badge justify-content-md-center" },
                    [
                      _c("div", { staticClass: "badge" }, [
                        _c("img", {
                          attrs: { src: _vm.createImgPath(_vm.iconPath) }
                        })
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "col-8 col-md-5" }, [
                _c("div", { staticClass: "right-box" }, [
                  _c("div", { staticClass: "status-name" }, [
                    _vm._v(
                      "Level " +
                        _vm._s(_vm.userLevel) +
                        " : " +
                        _vm._s(_vm.getLevelName(_vm.userLevel))
                    )
                  ]),
                  _c("div", { staticClass: "status-bar" }, [
                    _c(
                      "div",
                      { staticClass: "status-bar-innner" },
                      [
                        _c("div", { staticClass: "element text-right" }, [
                          _vm._v("ポイント:")
                        ]),
                        _c("b-progress", {
                          attrs: {
                            variant: "info",
                            value: _vm.climbingPoint,
                            max: _vm.statusBarMax,
                            "show-value": ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "status-bar-innner" },
                      [
                        _c("div", { staticClass: "element text-right" }, [
                          _vm._v("岩場達人度:")
                        ]),
                        _c("b-progress", {
                          attrs: {
                            variant: "info",
                            value: _vm.rockyMasterPoint,
                            max: _vm.statusBarMax,
                            "show-value": ""
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "status-bar-innner" },
                      [
                        _c("div", { staticClass: "element text-right" }, [
                          _vm._v("技術Lv:")
                        ]),
                        _c("b-progress", {
                          attrs: {
                            variant: "info",
                            value: _vm.techniquePoint,
                            max: _vm.techniquePointBarMax,
                            "show-value": ""
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "row justify-content-md-center bottom-text-area" },
              [
                _c("div", { staticClass: "col-12" }, [
                  _vm.isTargetIsLoginUserId
                    ? _c("div", { staticClass: "bottom-text" }, [
                        _vm._m(0),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(
                            "あと" +
                              _vm._s(_vm.pointRequiredNextLevel / 10) +
                              "チェックイン、または、" +
                              _vm._s(_vm.pointRequiredNextLevel) +
                              "登攀ポイント獲得"
                          )
                        ])
                      ])
                    : _vm._e(),
                  !_vm.isTargetIsLoginUserId & (_vm.profileText != "")
                    ? _c("div", { staticClass: "bottom-text" }, [
                        _vm._m(1),
                        _c("div", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.profileText))
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "row justify-content-md-center tab-area" },
              [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "navi-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        class: { active: _vm.isTopTab },
                        on: {
                          click: function($event) {
                            return _vm.changeTabAndRouterPush("userInfoTop")
                          }
                        }
                      },
                      [_vm._v("TOP")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        class: { active: _vm.isBadgeTab },
                        on: {
                          click: function($event) {
                            return _vm.changeTabAndRouterPush("badgeList")
                          }
                        }
                      },
                      [_vm._v("バッジ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        class: { active: _vm.isCheckInRallyTab },
                        on: {
                          click: function($event) {
                            return _vm.changeTabAndRouterPush("checkInRally")
                          }
                        }
                      },
                      [_vm._v("チェックインラリー")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        class: { active: _vm.isRockyTab },
                        on: {
                          click: function($event) {
                            return _vm.changeTabAndRouterPush("rockyList")
                          }
                        }
                      },
                      [_vm._v("岩場")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        class: { active: _vm.isAllRouteTab },
                        on: {
                          click: function($event) {
                            return _vm.changeTabAndRouterPush("allRouteList")
                          }
                        }
                      },
                      [_vm._v("全ルート")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        class: { active: _vm.isCheckInHistoryTab },
                        on: {
                          click: function($event) {
                            return _vm.changeTabAndRouterPush(
                              "checkInHistoryList"
                            )
                          }
                        }
                      },
                      [_vm._v("チェックイン")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "tab",
                        class: { active: _vm.isFriendsTab },
                        on: {
                          click: function($event) {
                            return _vm.changeTabAndRouterPush("friendsList")
                          }
                        }
                      },
                      [_vm._v("友達")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "child-area" },
                    [_c("router-view", { ref: "myPageChild" })],
                    1
                  )
                ])
              ]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("b", [_vm._v("次のレベルまで")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [_c("b", [_vm._v("自己紹介")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }